import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUtensils, faCoffee, faBeer, faCocktail, faPie, faConciergeBell} from '@fortawesome/pro-duotone-svg-icons';

import Section from '../ui/Section';
import {CONTAINER_BLOG_WIDTH, PRIMARY_COLOR, TEXT_COLOR, TEXT_MUTED1} from '../constants';
import {barsRoutes, hotelsRoute, nightClubsRoute, pasteryShopsRoute, pubsRoute, restaurantsRoute} from '../routes';
import Paragraph from '../ui/Paragraph';

export default function WhoFor() {
	return (
		<Section title="Za koga je QR Cjenik?">
			<Paragraph textAlign="center" maxWidth={CONTAINER_BLOG_WIDTH}>
				QR Cjenik je namjenjen svima tko treba ili želi napraviti svoje cijene dostupnima putem QR kȏda, a cijene prezentirati svojim
				kupcima i/ili gostima na jednostavan, pristupačan i inovativan način.
			</Paragraph>
			<Flexbox>
				<Item icon={faUtensils} title="Restorani" to={restaurantsRoute()}>
					Ubrzajte odluku vašeg gosta na njegovom jeziku.
				</Item>
				<Item icon={faCoffee} title="Barovi" to={barsRoutes()}>
					Jer vaši gosti žele informaciju ODMAH. Omogućite im to.
				</Item>
				<Item icon={faBeer} title="Pivnice" to={pubsRoute()}>
					Upoznajte svoje goste s novim pivima bez da ispituju vaše osoblje.
				</Item>
				<Item icon={faCocktail} title="Noćni klubovi" to={nightClubsRoute()}>
					Smanjite broj pogrešnih narudžbi u bučnim prostorima.
				</Item>
				<Item icon={faPie} title="Slastičarnice" to={pasteryShopsRoute()}>
					Ubacite fotografije slastica koje pravite i povećajte prodaju.
				</Item>
				<Item icon={faConciergeBell} title="Hoteli" to={hotelsRoute()}>
					Omogućite gostima ažurne informacije na jednom mjestu.
				</Item>
			</Flexbox>
			<Paragraph textAlign="center" maxWidth={CONTAINER_BLOG_WIDTH}>
				Osim u ugostiteljstvu, QR Cjenik je primjenjiv i u drugim djelatnostima, kao što su trgovine, suvenirnice, fitness centri, frizerski
				i kozmetički saloni, pekare, kiosci i sl.
			</Paragraph>
		</Section>
	);
}

const Flexbox = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	flex-direction: row;
	align-items: flex-start;
	margin: 3rem 0 0 0;

	> a,
	> div {
		width: 180px;
		box-sizing: border-box;
		padding: 0 18px;
		text-align: center;
		margin: 2rem 0;
		text-decoration: none;

		> span:first-of-type {
			display: block;
			font-size: 4rem;
			color: ${PRIMARY_COLOR};
			text-decoration: none;

			> svg {
				transform: none;
				will-change: transform;
				transition: transform 120ms ease-in-out;
			}
		}

		@media (hover: hover) and (pointer: fine) {
			&:hover > span:first-of-type > svg {
				transform: scale(1.18);
			}
		}

		> h3 {
			display: block;
			font-weight: bold;
			margin: 1.5rem 0;
			font-size: 1.2rem;
			text-decoration: none;
			color: ${TEXT_COLOR};
		}

		> p {
			margin: 1.5rem 0;
			text-align: center;
			line-height: 1.5;
			font-size: 0.9rem;
			font-weight: normal;
			color: ${TEXT_MUTED1};
			text-decoration: none;
		}

		> span:last-of-type {
			display: block;
			color: ${PRIMARY_COLOR};
			text-decoration: none;
		}

		@media (hover: hover) and (pointer: fine) {
			&:hover > span:last-of-type {
				text-decoration: underline;
			}
		}
	}
`;

const Item = function ({icon, children, title, to = null}) {
	return (
		<div>
			<span>
				<FontAwesomeIcon icon={icon} fixedWidth />
			</span>
			<h3>{title}</h3>
			<p>{children}</p>
		</div>
	);
};

Item.propTypes = {
	// eslint-disable-next-line
	icon: PropTypes.object.isRequired,
	children: PropTypes.node.isRequired,
	title: PropTypes.string.isRequired,
	to: PropTypes.string.isRequired
};
