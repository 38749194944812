import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {Link} from 'gatsby';
import Img from 'gatsby-image';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClock} from '@fortawesome/pro-regular-svg-icons';

import {FONT_FAMILY, TEXT_COLOR, TEXT_MUTED1, TEXT_MUTED2} from '../../constants';

export default function BlogPostVertical({title, coverImage, authorName, authorImage, minutesRead, url}) {
	return (
		<Wrapper to={url}>
			<Img fixed={coverImage} alt={title} objectFit="cover" objectPosition="center center" className="cover-img" />
			<div>
				<h3>{title}</h3>
				<div>
					<div>
						<Img fixed={authorImage} width={30} height={30} alt={authorName} className="author-img" />
						<span>{authorName}</span>
					</div>
					<div>
						{minutesRead} min read <FontAwesomeIcon icon={faClock} />
					</div>
				</div>
			</div>
		</Wrapper>
	);
}

BlogPostVertical.propTypes = {
	title: PropTypes.string.isRequired,
	// eslint-disable-next-line
	coverImage: PropTypes.object.isRequired,
	authorName: PropTypes.string.isRequired,
	// eslint-disable-next-line
	authorImage: PropTypes.object.isRequired,
	minutesRead: PropTypes.number.isRequired,
	url: PropTypes.string.isRequired
};

const Wrapper = styled(Link)`
	display: block;
	max-width: 350px;
	background: #ffffff;
	overflow: hidden;
	border-radius: 8px;
	box-shadow: 0 0 8px rgba(22, 22, 22, 0.1);
	transition: box-shadow 400ms ease;

	.cover-img {
		width: 100%;
		max-width: 100%;
	}

	&:hover {
		box-shadow: 0 0 12px rgba(22, 22, 22, 0.4);
	}

	> div {
		h3 {
			display: block;
			box-sizing: border-box;
			padding: 1rem;
			font-size: 1.3rem;
			font-weight: bold;
			line-height: 1.5;
			color: ${TEXT_COLOR};
			font-family: ${FONT_FAMILY};
		}

		> div {
			display: flex;
			justify-content: space-between;
			align-items: center;

			> div:first-child {
				// ^^ author image and name
				display: flex;
				align-items: center;
				justify-content: flex-start;
				flex-wrap: nowrap;
				box-sizing: border-box;
				padding: 0 0 1rem 1rem;

				> .author-img {
					border-radius: 100%;
					width: 30px;
					height: 30px;
				}

				> span {
					font-size: 0.75rem;
					color: ${TEXT_MUTED1};
					margin-left: 0.5rem;
				}
			}

			> div:last-child {
				font-size: 0.75rem;
				color: ${TEXT_MUTED1};
				box-sizing: border-box;
				padding: 0 1rem 1rem 0;

				> svg {
					color: ${TEXT_MUTED2};
					margin-left: 0.25rem;
				}
			}
		}
	}
`;
