import React from 'react';
import styled from 'styled-components';

import Section from '../ui/Section';
import Separator from './Separator';
import Paragraph from '../ui/Paragraph';
import BasicPricePackage from './PricePackages/BasicPricePackage';
import StandardPricePackage from './PricePackages/StandardPricePackage';
import PremiumPricePackage from './PricePackages/PremiumPricePackage';
import {CONTAINER_BLOG_WIDTH} from '../constants';

export default function Prices() {
	return (
		<>
			<Separator />
			<Section title="Cijene" id="cijene">
				<Paragraph textAlign="center">
					<small>
						Sve cijene su izražene s{' '}
						<strong>
							<u>uključenim PDV-om</u>
						</strong>
						.
					</small>
				</Paragraph>
				<Columns>
					<BasicPricePackage />
					<div />
					<StandardPricePackage />
					<div />
					<PremiumPricePackage />
				</Columns>
				<Paragraph maxWidth={CONTAINER_BLOG_WIDTH} textAlign="center">
					Nakon registracije kreće besplatni probni period u trajanju od 14 dana u kojem možete isprobati sve pakete. Ako ste zadovoljni
					uslugom, naplata kreće za razdoblje nakon probnog perioda.
				</Paragraph>
			</Section>
		</>
	);
}

const Columns = styled.div`
	display: block;
	width: 100%;
	margin: 3rem 0;

	> div {
		margin: 0 0 2rem 0;
		background: #ffffff;
		border-radius: 0.75rem;
		border: 4px solid #ffffff;
		position: relative;
		box-sizing: border-box;
		padding-bottom: 3rem;

		&:nth-child(2),
		&:nth-child(4) {
			background: transparent;
			border: none;
		}
	}

	@media (min-width: 767px) {
		display: flex;
		align-items: stretch;

		> div {
			box-sizing: border-box;
			flex: 1;

			&:nth-child(2),
			&:nth-child(4) {
				width: 2rem;
				flex: none;
				background: transparent;
				border: none;
			}
		}
	}
`;
