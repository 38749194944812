import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import Section from '../ui/Section';
import {CONTAINER_BLOG_WIDTH, PRIMARY_COLOR, PRIMARY_COLOR_1, PRIMARY_COLOR_3, TEXT_MUTED1} from '../constants';
import Separator from './Separator';
import Button from '../ui/Button';
import Paragraph from '../ui/Paragraph';

export default function HowItLooks() {
	const data = useStaticQuery(graphql`
		query {
			file(relativePath: {eq: "splendid-restoran-hand.jpg"}) {
				childImageSharp {
					# Specify a fixed image and fragment.
					# The default width is 400 pixels
					fluid(maxWidth: 600) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	return (
		<>
			<Separator />
			<Section title="Kako izgleda cjenik?" id="primjeri">
				<Paragraph textAlign="center" maxWidth={CONTAINER_BLOG_WIDTH}>
					Ovako će vaši gosti vidjeti Vaš cjenik. Pogledajte primjere:
				</Paragraph>
				<Columns>
					<div>
						<Img fluid={data.file.childImageSharp.fluid} />
					</div>
					<div>
						<ul>
							<li>
								<h4>Splendid Restoran</h4>
								<p>
									Pogledajte primjer cjenika prilagođen restoranima. Domena: <strong>danasnji.menu</strong>
								</p>
								<Button size="sm" as="a" href="https://danasnji.menu/splendid-restoran/433.200.543" target="_blank">
									Otvori: Splendid Restoran
								</Button>
							</li>
							<li>
								<h4>Splendid Pizzeria</h4>
								<p>
									Pogledajte primjer cjenika prilagođen pizzeriji. Domena: <strong>najbolja.pizza</strong>
								</p>
								<Button size="sm" as="a" href="https://najbolja.pizza/splendid-pizzeria/372.188.611" target="_blank">
									Otvori: Splendid Pizzeria
								</Button>
							</li>
							<li>
								<h4>Splendid Caffe</h4>
								<p>
									Pogledajte primjer cjenika prilagođen caffe baru. Domena: <strong>najbolji.bar</strong>
								</p>
								<Button size="sm" as="a" href="https://najbolji.bar/splendid-caffe/948.185.774" target="_blank">
									Otvori: Splendid Caffe
								</Button>
							</li>
							<li>
								<h4>Super Slastičarnica</h4>
								<p>
									Pogledajte primjer cjenika prilagođen slastičarnicama. Domena: <strong>cjenik.today</strong>
								</p>
								<Button size="sm" as="a" href="https://cjenik.today/super-slasticarnica/846.166.090" target="_blank">
									Otvori: Super Slastičarnica
								</Button>
							</li>
						</ul>
					</div>
				</Columns>
			</Section>
		</>
	);
}

const Columns = styled.div`
	display: block;
	width: 100%;

	> div {
		margin: 3rem 0;

		&:first-child {
			margin-left: -1rem;
			margin-right: -1rem;
		}
	}

	@media (min-width: 767px) {
		display: flex;
		align-items: flex-start;

		> div {
			box-sizing: border-box;
			flex: 1;

			&:first-child {
				padding-right: 3rem;
				margin-left: 0;
				margin-right: 0;
			}

			&:last-child {
				padding-left: 3rem;
			}
		}
	}

	ul {
		display: block;
		margin: 0;
		padding: 0;
		list-style: none;
		counter-reset: brojac;

		> li {
			display: block;
			width: 100%;
			margin: 1rem 0;
			box-sizing: border-box;
			padding: 0 0 0 60px;
			counter-increment: brojac;
			position: relative;
			min-height: 44px;

			&:before {
				content: counter(brojac);
				position: absolute;
				top: 0;
				left: 0;
				width: 40px;
				height: 40px;
				border-radius: 100%;
				color: ${PRIMARY_COLOR};
				border: 2px solid ${PRIMARY_COLOR};
				text-align: center;
				font-size: 1.1rem;
				line-height: 40px;
				font-weight: bold;
			}

			h4 {
				display: block;
				font-weight: normal;
				font-size: 1.25rem;
				box-sizing: border-box;
				padding: 0.55rem 0;
				color: ${PRIMARY_COLOR};
			}

			p {
				font-size: 0.92rem;
				line-height: 1.5;
				color: ${TEXT_MUTED1};
				margin: 0.33rem 0;

				&:first-child {
					margin-top: 0;
				}

				&:last-child {
					margin-bottom: 0;
				}

				a {
					color: ${PRIMARY_COLOR_3};
					border-bottom: 1px dotted ${PRIMARY_COLOR_1};

					&:hover {
						border-bottom: 1px solid ${PRIMARY_COLOR_3};
					}
				}
			}
		}
	}
`;
