export default [
	{
		id: 2,
		title: 'WiFi+QR u vašem objektu',
		description: 'Omogućite svojim gostima (u poslovnom prostoru ili doma) da se jednostavno spoje na vašu WiFi mrežu',
		minutesRead: 5,
		intro: 'Omogućite svojim gostima (u poslovnom prostoru ili doma) da se jednostavno spoje na vašu WiFi mrežu',
		author: 1,
		url: '/blog/qr-kodovi-za-wifi',
		date: '2021-07-29'
	},
	{
		id: 1,
		title: 'Što je QR kȏd?',
		description: 'Iako QR kȏd vidite već svugdje oko sebe, znate li uopće što znači kratica QR?',
		minutesRead: 4,
		intro:
			'Tri crna kvadratića sad već vidite svaki dan, gdje god krenete i gdje god pogledate: u trgovinama uz cijenu artikla, na fiskaliziranim računima, propusnicama, COVID putovnicama, Bitcoin novčanicima ili uređajima za naplatu parkinga - QR kȏd je tu. A znate li što je QR kȏd?',
		author: 1,
		url: '/blog/sto-je-qr-kod',
		date: '2021-03-23'
	}
];
