/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown} from '@fortawesome/pro-solid-svg-icons';

import Section from '../ui/Section';
import {PRIMARY_COLOR, PRIMARY_COLOR_1, PRIMARY_COLOR_3, TEXT_MUTED1} from '../constants';
import Separator from './Separator';

export default function Faq() {
	const toggle = async (e) => {
		const {currentTarget} = e;
		const {classList} = currentTarget.parentNode;

		if (classList.contains('active')) {
			classList.remove('active');
		} else {
			classList.add('active');
		}
	};

	return (
		<>
			<Separator />
			<Section title="Često postavljena pitanja?" id="faq">
				<Ul>
					<li>
						<h3 onClick={toggle}>
							<FontAwesomeIcon icon={faAngleDown} fixedWidth /> Tko sve može koristiti aplikaciju?
						</h3>
						<div>
							<p>
								QR-Cjenik mogu koristiti svi gosti s pametnim telefonim, a administrativni dio može koristiti bilo tko tko želi svoj cjenik
								digitalizirati.
							</p>
						</div>
					</li>
					<li>
						<h3 onClick={toggle}>
							<FontAwesomeIcon icon={faAngleDown} fixedWidth /> Kada usluga postaje aktivna?
						</h3>
						<div>
							<p>
								Usluga je aktivna ODMAH, čim se registirate i dodate svoje uslužno mjesto. Ne trebate nas kontaktirati da bi isprobali našu
								uslugu.
							</p>
						</div>
					</li>
					<li>
						<h3 onClick={toggle}>
							<FontAwesomeIcon icon={faAngleDown} fixedWidth /> Mogu li isprobati uslugu prije plaćanja?
						</h3>
						<div>
							<p>
								Da. Čim dodate svoje uslužno mjesto, dobit ćete 14 dana besplatnog probnog perioda unutar kojeg možete probati sve pakete
								usluga.
							</p>
						</div>
					</li>
					<li>
						<h3 onClick={toggle}>
							<FontAwesomeIcon icon={faAngleDown} fixedWidth /> Smijem li koristiti URL do svog cjenika za oglašavanje na Instagramu ili
							Facebooku?
						</h3>
						<div>
							<p>
								Da. Odaberite bilo koju od <em>cool</em> domena i slobodno ih iskoristite za vlastitu online promociju.
							</p>
						</div>
					</li>
					<li>
						<h3 onClick={toggle}>
							<FontAwesomeIcon icon={faAngleDown} fixedWidth /> Već koristim Glovo i/ili Wolt. Treba li mi još i ovo?
						</h3>
						<div>
							<p>
								Da. Odlično je što dostavljate svoje proizvode s već poznatim dostavnim servisima, ali je problem što nemaju svi vaši kupci
								sve te aplikacije instalirane na svom uređaju. Neki imaju možda samo jednu od tih aplikacija, a neki ni jednu. Za QR-Cjenik
								nije potrebno instalirati nikakvu dodatnu aplikaciju čime se smanjuje frikciju prema vašim kupcima, a Vi u svojem cjeniku
								postavite linkove do svoje ponude na dostavnim servisima čime kupcu ostavljate na izbor preko koga će naručiti vaše
								proizvode.
							</p>
						</div>
					</li>
					<li>
						<h3 onClick={toggle}>
							<FontAwesomeIcon icon={faAngleDown} fixedWidth /> Smijem li koristiti jedan QR kȏd na više lokacija?
						</h3>
						<div>
							<p>
								Da. Pitanje je samo, želite li Vi to? Korištenjem jednog QR koda znači da ćete na više lokacija imati potpuno identičan
								cjenik. Ako je Vaš slučaj takav da to i trebate, onda možete koristiti našu uslugu i na takav način.
							</p>
						</div>
					</li>
					<li>
						<h3 onClick={toggle}>
							<FontAwesomeIcon icon={faAngleDown} fixedWidth /> Mogu li preko vas naručiti QR naljepnice?
						</h3>
						<div>
							<p>
								Da, ali samo ako produžite i platite uslugu QR-Cjenika za minimalno godinu dana. U tom slučaju, naljepnice su za Vas{' '}
								<strong>besplatne</strong>.
							</p>
						</div>
					</li>
				</Ul>
			</Section>
		</>
	);
}

const Ul = styled.div`
	display: block;
	width: 100%;
	list-style-type: none;
	margin: 2rem 0;

	> li {
		display: block;
		width: 100%;
		margin: 1rem 0;
		box-sizing: border-box;
		padding: 0;
		position: relative;

		svg {
			transform: rotate(-90deg);
			will-change: transform;
			transition: transform 300ms ease;
		}

		h3 {
			display: block;
			font-weight: normal;
			font-size: 1rem;
			box-sizing: border-box;
			padding: 0;
			color: ${PRIMARY_COLOR};
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}

		p {
			display: block;
			font-size: 0.85rem;
			line-height: 1.5;
			color: ${TEXT_MUTED1};
			margin: 0.5rem 0;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}

			a {
				color: ${PRIMARY_COLOR_3};
				border-bottom: 1px dotted ${PRIMARY_COLOR_1};

				&:hover {
					border-bottom: 1px solid ${PRIMARY_COLOR_3};
				}
			}
		}

		> div {
			overflow: hidden;
			height: 1px;
			transition: height 300ms ease;
			box-sizing: border-box;
		}

		&.active {
			svg {
				transform: rotate(0deg);
			}

			> div {
				height: auto;
				padding-top: 1rem;
			}
		}
	}

	@media (min-width: 992px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 2rem;

		> div {
			box-sizing: border-box;
			flex: 1;

			&:first-child {
				padding-right: 3rem;
			}

			&:last-child {
				padding-left: 3rem;
			}
		}
	}
`;
