import React from 'react';
import styled from 'styled-components';

import {FONT_FAMILY, PRIMARY_COLOR_2} from '../constants';
import Wave from '../ui/Wave';

/**
 * @returns {JSX.Element}
 * @constructor
 *
 * @link https://dev.to/theliquidcharcoal/pure-html-animation-animate-svg-with-animate-2a5m
 */
export default function Intro() {
	return (
		<>
			<Wrapper>
				<div>
					<span>Vaš beskontaktni cjenik dostupan putem QR kȏda i weba</span>
					<small>Brzo. Jednostavno. Inovativno.</small>
				</div>
			</Wrapper>
			<Wave height={110} orientation="below" />
		</>
	);
}

const Wrapper = styled.div`
	display: flex;
	background: ${PRIMARY_COLOR_2};
	height: 500px;
	align-items: center;

	> div {
		display: block;
		width: 100%;
		text-align: center;
		box-sizing: border-box;
		padding: 0 2rem;

		> span,
		> small {
			display: block;
			width: 100%;
			max-width: 800px;
			margin: 1rem auto;
			text-align: center;
			font-family: ${FONT_FAMILY};
			font-weight: 700;
			font-size: 2rem;
			color: #ffffff;
		}

		> small {
			font-size: 1.5rem;
			
			&:first-of-type {
				margin-top: 2.5rem;
			}
		}
	}
	
	@media (min-width: 380px) {
		> div > span {
			font-size: 2.5rem;
		}
	}
	
	@media (min-width: 480px) {
		> div > span {
			font-size: 3rem;
		}
	}
`;
