/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import styled from 'styled-components';

import Section from '../ui/Section';
import Separator from './Separator';
import BlogPostVertical from './Blog/BlogPostVertical';

import blog from '../../data/blog';
import authors from '../../data/author';

export default function BlogPostsOnIndex() {
	const data = useStaticQuery(graphql`
		query {
			coverImage1: file(relativePath: {eq: "blog/wifi-coffee.jpg"}) {
				childImageSharp {
					# Specify a fixed image and fragment.
					# The default width is 400 pixels
					fixed(height: 260) {
						...GatsbyImageSharpFixed
					}
				}
			}
			coverImage2: file(relativePath: {eq: "qr/qr-cover.png"}) {
				childImageSharp {
					# Specify a fixed image and fragment.
					# The default width is 400 pixels
					fixed(height: 260) {
						...GatsbyImageSharpFixed
					}
				}
			}
			authorImage: file(relativePath: {eq: "author/vlatko.jpg"}) {
				childImageSharp {
					fixed(width: 30) {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	`);

	const post1 = blog[0];
	const post2 = blog[1];

	const vlatko = authors[0];

	return (
		<>
			<Separator />
			<Section title="Blog" id="blog">
				<Nav>
					<BlogPostVertical
						title={post1.title}
						authorName={vlatko.name}
						authorImage={data.authorImage.childImageSharp.fixed}
						coverImage={data.coverImage1.childImageSharp.fixed}
						url={post1.url}
						minutesRead={post1.minutesRead}
					/>
					<BlogPostVertical
						title={post2.title}
						authorName={vlatko.name}
						authorImage={data.authorImage.childImageSharp.fixed}
						coverImage={data.coverImage2.childImageSharp.fixed}
						url={post2.url}
						minutesRead={post2.minutesRead}
					/>
				</Nav>
			</Section>
		</>
	);
}

const Nav = styled.nav`
	width: 100%;
	display: block;

	> a {
		margin: 4rem auto;
		display: block;
	}

	@media (min-width: 767px) {
		display: flex;
		align-items: stretch;
		justify-content: center;

		> a {
			margin: 4rem 1.5rem;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
`;
