import React from 'react';
import PropTypes from 'prop-types';
import styled, {css} from 'styled-components';

import {TEXT_COLOR} from '../constants';
import {getPixelsOrString, getStyleForMargins, isNumberOrString} from '../helpers';

export default function Title({children: title, fontSize = null, m = null, mt = null, mr = null, mb = null, ml = null, ...otherProps}) {
	return (
		<H2 $fontSize={fontSize} $m={m} $mt={mt} $mr={mr} $mb={mb} $ml={ml} {...otherProps}>
			{title}
		</H2>
	);
}

Title.propTypes = {
	children: PropTypes.string.isRequired,
	fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	// margins:
	m: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	mt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	mr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	ml: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const H2 = styled.h2`
	display: block;
	margin: 0 auto;
	box-sizing: border-box;
	padding: 0;
	font-size: ${({$fontSize}) => (isNumberOrString($fontSize) ? getPixelsOrString($fontSize) : '2rem')};
	text-align: center;
	font-weight: bold;
	color: ${TEXT_COLOR};

	${({$m, $mt, $mr, $mb, $ml}) => css(getStyleForMargins({m: $m, mt: $mt, mr: $mr, mb: $mb, ml: $ml}))};
`;
