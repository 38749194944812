import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {PRIMARY_COLOR_2} from '../constants';

export default function Wave({height, orientation}) {
	const orientationIndex = orientation === 'below' ? '9' : '359';

	return (
		<Svg width="100%" height={`${height}px`} fill="none" preserveAspectRatio="none">
			<path fill={PRIMARY_COLOR_2} d={`M 0 57 C 273,183 822,-40 2560,106 V ${orientationIndex} H 0 V 67 Z`} />
		</Svg>
	);
}

Wave.propTypes = {
	height: PropTypes.number.isRequired,
	orientation: PropTypes.oneOf(['below', 'above']).isRequired
};

const Svg = styled.svg`
	margin: -10px 0;
	padding: 0;
	position: relative;
	z-index: 5;
`;
