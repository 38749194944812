import React from 'react';

import {aboutStandardRoute} from '../../routes';
import PricePackage from '../PricePackage';
import {PRIMARY_COLOR, PRIMARY_COLOR_2} from '../../constants';

export default function StandardPricePackage() {
	return (
		<PricePackage
			name="STANDARD"
			aboutLinkTo={aboutStandardRoute()}
			priceEUR={13}
			priceCENT={25}
			color1={PRIMARY_COLOR_2}
			color2={PRIMARY_COLOR}
		>
			<li>Sve iz BASIC paketa</li>
			<li>Mogućnost prijevoda svih elemenata cjenika na jezike koje odaberete</li>
			<li>Mogućnost dodavanja suradnika</li>
			<li>Mogućnost ubacivanja neograničenog broja tekstova</li>
			<li>Mogućnost odabira domene cjenika</li>
		</PricePackage>
	);
}
