import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {CONTAINER_WIDTH} from '../constants';
import Title from './Title';

export default function Section({children, title = null, id = null}) {
	return (
		<Wrapper>
			{title && <Title id={id}>{title}</Title>}
			{children}
		</Wrapper>
	);
}

Section.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string,
	id: PropTypes.string
};

const Wrapper = styled.section`
	display: block;
	padding: 0 1rem;
	margin: 4rem auto 8rem auto;
	width: 100%;
	max-width: ${CONTAINER_WIDTH};
`;
