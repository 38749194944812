import React from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClock, faEdit, faEuroSign, faFileInvoice, faInfoSquare, faMobileAlt, faVirusSlash} from '@fortawesome/pro-light-svg-icons';
import {faBullseyePointer} from '@fortawesome/pro-duotone-svg-icons';

import Section from '../ui/Section';
import {PRIMARY_COLOR, PRIMARY_COLOR_1, PRIMARY_COLOR_3, TEXT_MUTED1} from '../constants';
import Separator from './Separator';

export default function WhyQrCjenik() {
	return (
		<>
			<Separator />
			<Section title="Zašto odabrati QR-Cjenik.hr?" id="zasto-qr-cjenik">
				<Columns>
					<div>
						<ul>
							<li>
								<FontAwesomeIcon icon={faEuroSign} />
								<h3>Dvojno iskazivanje u EUR i HRK</h3>
								<p>
									Dvojno iskazivanje cijena je trenutno obavezno u Hrvatskoj. Sustav će za vas po fiksnom tečaju automatski pretvoriti EUR
									cijene u HRK odmah pri unosu tako da ne morate dva puta unositi cijene za istu stavku.
								</p>
							</li>
							<li>
								<FontAwesomeIcon icon={faMobileAlt} />
								<h3>Jer Vaši gosti to žele</h3>
								<p>
									Koliko puta vam se dogodilo da gosti sjednu za stol i odmah žele naručiti, a osoblje baš u tom trenutku ne može odmah
									doći? A onda kada osoblje stigne do gosta, gosti krenu ispitivati o ponudi. Vrijeme je novac, a QR kȏd omogućava svakom
									gostu ažurnu informaciju ODMAH, a to štedi vrijeme vašem osoblju!
								</p>
							</li>
							<li>
								<FontAwesomeIcon icon={faInfoSquare} />
								<h3>Jer informacija je - sve</h3>
								<p>
									Uz svaki artikl koji ubacite u svoj cjenik možete postaviti sliku visoke rezolucije, dodatni tekst i cijenu za sve
									varijante ili količine vašeg proizvoda. Svi tekstovi se mogu prevesti na više jezika, tako da vaša ponuda bude razumljiva
									i negovornicima hrvatskog jezika. Artikl možete označiti popularnim ili, po potrebi, nedostupnim. Svi artikli se mogu
									pretraživati, a to gostu omogućuje brzi dolazak do informacije koju traži.
								</p>
							</li>
							<li>
								<FontAwesomeIcon icon={faVirusSlash} />
								<h3>Jer je virus-free</h3>
								<p>
									Iako je vrijeme pandemije iza nas, QR Cjenik će Vam omogućiti da opipljivi cjenici koji idu od ruke do ruke postanu stvar
									prošlosti, a istovremeno još bolji i epidemiološki prihvatljivi.
								</p>
							</li>
							<li>
								<FontAwesomeIcon icon={faFileInvoice} />
								<h3>Jer smo potpuno transparentni</h3>
								<p>
									Naše cijene su javne i možete ih provjeriti i prije nego se registrirate kako bi mogli kontrolirati i točno planirati
									svoje troškove.
								</p>
							</li>
						</ul>
					</div>
					<div>
						<ul>
							<li>
								<FontAwesomeIcon icon={faEdit} />
								<h3>Jer je kontrola potpuno u Vašim rukama</h3>
								<p>
									Svoj cjenik možete uređivati koliko god želite i kad god želite. I ne samo to - možete ga i automatizirati tako da ne
									trebate svaki dan biti online kako bi promijenili informacije u cjeniku! Sav sadržaj možete vremenski targetirati da se
									pojavi na točno određeni datum i vrijeme, a možete i postaviti da se sadržaj pojavljuje periodično, npr. svaki dan od
									08-10h ujutro. To vam omogućuje kreiranje <em>happy hour-a</em>, akcije ili dnevne, tjedne, pa čak i mjesečne menije -
									unaprijed.
								</p>
							</li>
							<li>
								<FontAwesomeIcon icon={faBullseyePointer} />
								<h3>Jer je naš cjenik - više od cjenika</h3>
								<p>Ako je digitalno, znači da je online. A zašto ne bi onda iskoristili online cjenik?</p>
								<p>
									Maksimum iz svog cjenika možete izvući uz podešavanje svega par postavki vašeg cjenika čime ćete svoj cjenik napraviti
									privlačnim pri dijeljenju linka na društvenim mrežama ili u online marketinškim kampanjama. Iz tog razloga smo omogućili
									korištenje nekoliko cool domena.
								</p>
							</li>
							<li>
								<FontAwesomeIcon icon={faClock} />
								<h3>Jer je okej ne imati vremena za ovo</h3>
								<p>
									Ako nemate vremena sami urediti svoj cjenik, prepustite taj posao nekom drugom. Nakon što se registrirate, dodajte
									suradnike, dodijelite im prava pristupa i to je to. Ovisno o pravima pristupa, možete napraviti da vaš suradnik vidi samo
									dio vezan za uređivanje cjenika, bez da ima pristup u financijski dio.
								</p>
								<p>
									Ako nemate nikoga za suradnika, imate - NAS! Iskoristite našu uslugu <em>Nemam vremena</em>, pošaljite sve materijale koje
									imate i mi ćemo kreirati cjenik za VAS!
								</p>
							</li>
						</ul>
					</div>
				</Columns>
			</Section>
		</>
	);
}

const Columns = styled.div`
	display: block;
	width: 100%;
	margin: 3rem 0;

	> div {
		margin: 0;
	}

	@media (min-width: 767px) {
		display: flex;
		align-items: flex-start;

		> div {
			box-sizing: border-box;
			flex: 1;

			&:first-child {
				padding: 0 1rem;
				margin-left: 0;
				margin-right: 0;
			}

			&:last-child {
				padding: 0 1rem;
			}
		}
	}

	@media (min-width: 992px) {
		> div {
			&:first-child {
				padding: 0 2.5rem 0 2rem;
			}

			&:last-child {
				padding: 0 2rem 0 2.5rem;
			}
		}
	}

	ul {
		display: block;
		margin: 0;
		padding: 0;
		list-style: none;

		> li {
			display: block;
			width: 100%;
			margin: 1.75rem 0;
			box-sizing: border-box;
			padding: 0 0 0 50px;
			position: relative;
			min-height: 44px;

			> svg {
				position: absolute;
				top: 0.25rem;
				left: 0;
				font-size: 2rem;
				color: ${PRIMARY_COLOR};
			}

			h3 {
				display: block;
				font-weight: normal;
				font-size: 1.25rem;
				box-sizing: border-box;
				padding: 0.55rem 0;
				color: ${PRIMARY_COLOR};
			}

			p {
				font-size: 0.925rem;
				line-height: 1.5;
				color: ${TEXT_MUTED1};
				margin: 0.33rem 0;

				&:first-child {
					margin-top: 0;
				}

				&:last-child {
					margin-bottom: 0;
				}

				a {
					color: ${PRIMARY_COLOR_3};
					border-bottom: 1px dotted ${PRIMARY_COLOR_1};

					&:hover {
						border-bottom: 1px solid ${PRIMARY_COLOR_3};
					}
				}
			}
		}
	}
`;
