import React from 'react';

import Layout from '../components/Layout';
import Nav from '../components/Nav';
import Intro from '../components/Intro';
import WhoFor from '../components/WhoFor';
import Footer from '../components/Footer';
import SignUpCTA from '../components/SignUpCTA';
import {PRIMARY_COLOR_2} from '../constants';
import HowItsWorking from '../components/HowItsWorking';
import HowItLooks from '../components/HowItLooks';
import WhyQrCjenik from '../components/WhyQrCjenik';
import Prices from '../components/Prices';
import EnvData from '../components/EnvData';
import Faq from '../components/Faq';
import BlogPostsOnIndex from '../components/BlogPostsOnIndex';

export default function IndexPage() {
	return (
		<Layout
			title="QR Cjenik"
			description="QR Cjenik je usluga koja ugostiteljima pruža mogućnost kreiranja beskontaktnog cjenika dostupnog putem QR kȏda i weba, a gostima omogućuje brzi, jednostavni i uvijek ažurni cjenik."
			uri="/"
		>
			<EnvData>
				<Nav background={PRIMARY_COLOR_2} />
				<Intro />
				<WhoFor />
				<HowItsWorking />
				<HowItLooks />
				<WhyQrCjenik />
				<Prices />
				<Faq />
				<BlogPostsOnIndex />
				<SignUpCTA />
				<Footer />
			</EnvData>
		</Layout>
	);
}
