import React from 'react';

import {aboutBasicRoute} from '../../routes';
import PricePackage from '../PricePackage';

export default function BasicPricePackage() {
	return (
		<PricePackage name="BASIC" aboutLinkTo={aboutBasicRoute()} priceEUR={7} priceCENT={95} color2="#31c192" color1="#008a60">
			<li>Mogućnost unosa 10000 stavki cjenika</li>
			<li>Neograničen broj skeniranja QR koda</li>
			<li>Označavanje stavke dostupnom/nedostupnom ili popularnom</li>
			<li>Dva teksta</li>
			<li>Mogućnost isticanja načina plaćanja</li>
			<li>Odabir izgleda cjenika i vizualne prilagodbe</li>
			<li>Vaši gosti imaju mogućnost pretraživanja cjenika</li>
		</PricePackage>
	);
}
