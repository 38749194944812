import styled from 'styled-components';
import {PRIMARY_COLOR_2} from '../constants';

const Separator = styled.div`
	display: block;
	width: 100%;
	height: 17px;
	overflow: hidden;

	&:after {
		display: block;
		content: ' ';
		width: 100%;
		height: 17px;
		background: ${PRIMARY_COLOR_2};
		transform: skew(${Math.round(Math.random()) === 0 ? '93deg' : '-93deg'});
	}
`;

export default Separator;
