import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import Section from '../ui/Section';
import {PRIMARY_COLOR, PRIMARY_COLOR_1, PRIMARY_COLOR_3, TEXT_MUTED1} from '../constants';
import SubTitle from '../ui/SubTitle';
import useEnvContext from '../hooks/useEnvContext';
import Separator from './Separator';

export default function HowItsWorking() {
	const {appUrl} = useEnvContext();

	return (
		<>
			<Separator />
			<Section title="Kako radi QR-Cjenik.hr?" id="kako-radi">
				<Columns>
					<div>
						<SubTitle>Iz perspektive vašeg gosta ili kupca:</SubTitle>
						<div className="img-max-500 ml-auto mr-auto mt-2 mb-2">
							<img src="/images/skeniraj-za-cjenik.jpg" alt="Skeniraj za cjenik" />
						</div>
						<ul>
							<li>
								<h4>Skenirajte QR kȏd svojim pametnim telefonom</h4>
								<p>
									Moderni pametni telefoni omogućavaju skeniranje QR kodova na način da upalite kameru, usmjerite ju prema QR kȏdu i
									pričekate da vam uređaj pokaže indikaciju da je kȏd skeniran.
								</p>
								<p>
									U slučaju da taj način ne radi, otvorite web preglednik i otvorite adresu{' '}
									<a href="https://nas.qr-cjenik.hr" target="_blank">
										https://nas.qr-cjenik.hr
									</a>{' '}
									- to je stranica koja će vam pomoći očitati QR kȏd u vašem pregledniku bez ikakve potrebe za instalacijom dodatne
									aplikacije.
								</p>
							</li>
							<li>
								<h4>Potvrdite skenirani URL</h4>
								<p>
									Ako je kamera vašeg pametnog telefona uspjela očitati QR kȏd, ovisno o uređaju, dobit ćete neki oblik potvrde koji trebate
									pritisnuti kako bi otvorili skeniranu adresu. Na iOS uređajima to izgleda ovako:
								</p>
								<IosNotification />
							</li>
							<li>
								<h4>Pogledajte ponudu</h4>
								<p>
									Cjenik koji se otvara nakon skeniranja QR kȏda je posebno prilagođen mobilnim uređajima, a omogućava pregled cijena,
									otvaranje detalja o stavki cjenika, pretraživanje cjenika prema traženoj riječi, itd... Primjere cjenika pogledajte u
									nastavku ove stranice.
								</p>
							</li>
						</ul>
					</div>
					<div>
						<SubTitle>Iz vaše perspektive:</SubTitle>
						<div className="img-max-500 ml-auto mr-auto mt-2 mb-2">
							<img src="/images/radni-stol.jpg" alt="Radni stol" />
						</div>
						<ul>
							<li>
								<h4>Besplatno se registrirajte</h4>
								<p>
									Registrirajte se na{' '}
									<a href={appUrl('registracija')} target="_blank">
										{appUrl('registracija')}
									</a>{' '}
									kao fizička osoba.
								</p>
							</li>
							<li>
								<h4>Dodajte uslužno mjesto</h4>
								<p>
									Nakon registracije dodajte uslužno mjesto za koje planirate koristiti uslugu QR Cjenika. Možete dodati i više lokacija na
									kojima planirate koristiti QR Cjenik.
								</p>
							</li>
							<li>
								<h4>Kreirajte cjenik</h4>
								<p>
									Čim ste kreirali uslužno mjesto, automatski će se dodati jedan prazan cjenik. Cjenik napunite svojim cijenama, tekstovima,
									slikama i drugim sadržajem. Odaberite temu, podesite veličine fontova, boje i ostale vizualne elemente kako bi Vaš cjenik
									bio atraktivan.
								</p>
							</li>
							<li>
								<h4>Zadnji korak - QR kodovi</h4>
								<p>
									Čim ste kreirali uslužno mjesto, automatski se kreirao jedan QR kȏd. Ovisno o paketu usluge, možete kreirati dodatne QR
									kȏdve kako bi kasnije imali uvid u statistiku skeniranja. Preuzmite QR kodove, isprintajte ih ili ih izradite u tiskari i
									postavite ih u svom uslužnom objektu na sva mjesta gdje mislite da treba: na sve stolove, na ulazna vrata, na tiskane
									promo materijale, <em>blackboard</em> ili bilo što drugo.
								</p>
							</li>
						</ul>
					</div>
				</Columns>
			</Section>
		</>
	);
}

const IosNotification = function () {
	const data = useStaticQuery(graphql`
		query {
			file(relativePath: {eq: "qr-scan-notification-ios.png"}) {
				childImageSharp {
					# Specify a fixed image and fragment.
					# The default width is 400 pixels
					fixed(width: 280) {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	`);

	return <Img fixed={data.file.childImageSharp.fixed} />;
};

const Columns = styled.div`
	display: block;
	width: 100%;

	> div {
		margin: 3rem 0;
	}

	@media (min-width: 767px) {
		display: flex;
		align-items: flex-start;

		> div {
			box-sizing: border-box;
			flex: 1;

			&:first-child {
				padding-right: 3rem;
			}

			&:last-child {
				padding-left: 3rem;
			}
		}
	}

	ul {
		display: block;
		margin: 0;
		padding: 0;
		list-style: none;
		counter-reset: brojac;

		> li {
			display: block;
			width: 100%;
			margin: 1rem 0;
			box-sizing: border-box;
			padding: 0 0 0 60px;
			counter-increment: brojac;
			position: relative;
			min-height: 44px;

			&:before {
				content: counter(brojac);
				position: absolute;
				top: 0;
				left: 0;
				width: 40px;
				height: 40px;
				border-radius: 100%;
				color: ${PRIMARY_COLOR};
				border: 2px solid ${PRIMARY_COLOR};
				text-align: center;
				font-size: 1.1rem;
				line-height: 40px;
				font-weight: bold;
			}

			h4 {
				display: block;
				font-weight: normal;
				font-size: 1.25rem;
				box-sizing: border-box;
				padding: 0.55rem 0;
				color: ${PRIMARY_COLOR};
			}

			p {
				font-size: 0.92rem;
				line-height: 1.5;
				color: ${TEXT_MUTED1};
				margin: 0.33rem 0;

				&:first-child {
					margin-top: 0;
				}

				&:last-child {
					margin-bottom: 0;
				}

				a {
					color: ${PRIMARY_COLOR_3};
					border-bottom: 1px dotted ${PRIMARY_COLOR_1};

					&:hover {
						border-bottom: 1px solid ${PRIMARY_COLOR_3};
					}
				}
			}
		}
	}
`;
